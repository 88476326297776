import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/firestore';
import "firebase/analytics";
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  created(){
    const firebaseConfig = {
      apiKey: "AIzaSyARNVhLTR2mQvXk5K0_ScMoiYoEsi9xagE",
      authDomain: "lazlo-show.firebaseapp.com",
      projectId: "lazlo-show",
      storageBucket: "lazlo-show.appspot.com",
      messagingSenderId: "1001789130307",
      appId: "1:1001789130307:web:6ad1a753351b096980b6f6",
      measurementId: "G-Q57GYJVTKH"
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    
  }
}).$mount('#app')
