import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shows:null,
    videos:null,
  },
  mutations: {

    setShows(state,payload){
      state.shows=payload
    },
    addShow(state,payload){
      state.shows.push(payload)
    },
    setVideos(state,payload){
      state.videos=payload
    },
    addVideo(state,payload){
      state.videos.push(payload)
    }
  },
  actions: {
    loadShows({commit},payload){
      commit('setShows', [])
      firebase.firestore().collection("Shows").get().then(querySnapshot => {
        if(!querySnapshot.empty){
          querySnapshot.forEach(doc => {
            let data=doc.data()
            commit('addShow',data);
        })
      }else{
        console.log("QUERY NO DATA")
        
      }
    }).catch(err => {
      console.log('Error logged', err);
      })
    },
    loadVideos({commit},payload){
      let vids=[]
      //commit('setVideos', [])
      firebase.firestore().collection("Videos").get().then(querySnapshot => {
        if(!querySnapshot.empty){
          //commit('setVideos',querySnapshot.docs)
          querySnapshot.forEach(doc => {
            let data=doc.data()
            data.config={
              autoplay: 1,
              allowfullscreen:"allowfullscreen",
              color: 'white',
              // You can use any params from https://developers.google.com/youtube/player_parameters#Manual_IFrame_Embeds
          }
            vids.push(data)
            vids.sort(function (a, b) {
              return a.index - b.index;
            });
            //commit('addVideo',data);
        })
        commit('setVideos', vids)
      }else{
        console.log("QUERY NO DATA")
        
      }
    }).catch(err => {
      console.log('Error logged', err);
      })
    }
  },
  
  getters:{
    getShows(state){
      return state.shows
    },
    getVideos(state){
      return state.videos
    }
  },
  modules: {
  }
})
